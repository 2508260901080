import React from 'react';

import { PartnerLogos, Typography, Container } from '@hpstellar/core';

import { getPartnerLogoContent } from '../../utility/setHtml';

import './title-section.less';

export default function TitleSection({ title, partnerLogoProps, className, typographyVariant, titleTag = 'h1' }) {
    return (
        <Container>
            <div className={`${className} titleSection`}>
                <Typography className="title" tag={titleTag} variant={typographyVariant || 'titleL'}>
                    {title}
                </Typography>
                <PartnerLogos logos={getPartnerLogoContent(partnerLogoProps?.logos)} className="logo" />
            </div>
        </Container>
    );
}
