import React from 'react';
import { HeroBanner } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

import { getHeroBannerContent } from '../../../utility/setHtml';

import './hero-banner.less';

const heroBanner = ({ categoryHeroBanner }) => {
    if ((categoryHeroBanner || []).length < 1) return null;

    const singleSlide = categoryHeroBanner.length === 1;

    return (
        <HeroBanner
            className={`categoryHeroBanner ${singleSlide ? ' singleSlide' : ''}`}
            banners={getHeroBannerContent(categoryHeroBanner)}
        />
    );
};

export default withPageComponents(heroBanner, { components: ['categoryHeroBanner'] });
