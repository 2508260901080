import React from 'react';
import loadable from '@loadable/component';

import { useLazyLoadSetttings } from '../../hooks/useSiteConfig';

import HeroBanner from './hero-banner';
import CategoryTiles from './category-tiles';
import ModelByUse from './model-by-use';
import SecondaryBanner from './secondary-banner';
import ModelByBrand from './model-by-brand';
import PromoTiles from './promo-titles';
import CarePacks from './care-packs';
import AccessoriesContent from './accessories-content';
import ShopByCategory from './shop-by-category';
import TilesTwoThree from './category-tiles-two-three';
import AccessoriesTopSellers from './accessories-top-sellers';
import InkTonerExclusives from './ink-toner-exclusives';
import RelatedArticles from './related-articles';
import FooterPartnerLogo from './footer-partner-logo';
import { Helpers } from '../../core/src/helpers';

import './category-landing-page.less';

const Einstein = loadable(() => Helpers.retryFunc(() => import('../../einstein')));
const SuppliesFinder = loadable(() => Helpers.retryFunc(() => import('../../supplies-finder')));

const CLP = ({ slugInfo }) => {
    const { vanityUrl, templateKey, components } = slugInfo || {};
    const { clpEinstein } = components || {};
    const shippingMessage = 'Includes FREE Shipping';
    const lazyLoadSettings = useLazyLoadSetttings(templateKey, vanityUrl);

    return (
        <div className="category-landing-page">
            <HeroBanner />
            <CarePacks />
            {/* only for accessories and it's above the fold so we aren't lazy loading*/}
            <AccessoriesContent />
            {vanityUrl === 'cat/ink--toner---paper' && (
                <SuppliesFinder
                    header="Find your ink or toner cartridges"
                    subHeader={shippingMessage}
                    mobileSubHeader={shippingMessage}
                    enableKeywordQueryParam={true}
                />
            )}
            <InkTonerExclusives />
            {/* only for accessories and it's just below the fold so we aren't lazy loading*/}
            <ShopByCategory />
            <TilesTwoThree lazyLoadSettings={lazyLoadSettings} />
            {/* Lazyload control in underlying category tiles*/}
            <CategoryTiles templateLazyLoadSettings={lazyLoadSettings} />
            <ModelByUse lazyLoadSettings={lazyLoadSettings} />
            {clpEinstein && clpEinstein.einsteinConfig && clpEinstein.einsteinConfig.einsteinScript && (
                <Einstein {...clpEinstein.einsteinConfig} />
            )}
            {vanityUrl === 'cat/printers' && <div id="dy-printer-rec"></div>}
            <SecondaryBanner lazyLoadSettings={lazyLoadSettings} />
            <ModelByBrand lazyLoadSettings={lazyLoadSettings} />
            <PromoTiles lazyLoadSettings={lazyLoadSettings} />
            <AccessoriesTopSellers lazyLoadSettings={lazyLoadSettings} />
            <RelatedArticles lazyLoadSettings={lazyLoadSettings} />
            <FooterPartnerLogo lazyLoadSettings={lazyLoadSettings} />
        </div>
    );
};

export default CLP;
