import React from 'react';
import { CategoryTiles } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import TitleSection from '../../../shared/components/title-section';
import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import CategoryTilesLoader from './skeleton-loader';

import { getTileContent } from '../../../utility/setHtml';

import './category-tiles.less';

const Tiles = ({
    highlightedGrid = {},
    templateLazyLoadSettings = {},
    categoryTitleSection = {},
    vanityUrl,
    width,
}) => {
    const tileLayouts = highlightedGrid?.tileLayouts;

    if (!tileLayouts) {
        return null;
    }

    const { orientation, tiles, sectionHeading, variation } = tileLayouts;

    return (
        <div className="categoryTilesContainer">
            <TitleSection
                className="categoryTitleSection stellarFix"
                title={sectionHeading}
                partnerLogoProps={categoryTitleSection}
                typographyVariant={'display'}
            />
            <AboveTheFoldOnlyServerRender {...templateLazyLoadSettings} placeholder={<CategoryTilesLoader />}>
                <CategoryTiles
                    className="categoryTiles"
                    tiles={tiles.map(getTileContent(width <= 768))}
                    orientation={orientation}
                    variation={variation}
                />
            </AboveTheFoldOnlyServerRender>
        </div>
    );
};

export default withPageComponents(Tiles, { components: ['highlightedGrid', 'categoryTitleSection'], device: true });
